import React from 'react';
import CCNS from '@/assets/CCNS_footer.svg';
import { Row, StyledIconImg } from '@/styles/common';
import { StyledContent, StyledDesktop, StyledRow, StyledText } from './Styles';

const Footer = () => (
  <>
    <StyledIconImg src={CCNS} width="100%" />
    <StyledContent>
      <StyledDesktop>
        <StyledRow justify="space-between">
          <StyledText>本站站長：lman</StyledText>
          <StyledText>系統站長：magicallove</StyledText>
        </StyledRow>
        <StyledText>站務群： Ender&nbsp;owenshih&nbsp;ianwolf&nbsp;&nbsp;EEWindFil</StyledText>
        <Row>
          <StyledText>Web3技術提供：</StyledText>
          <StyledText>
            <a href="https://www.domin.foundation/" target="_blank" rel="noreferrer">
              Domin Network
            </a>
          </StyledText>
        </Row>
      </StyledDesktop>
    </StyledContent>
  </>
);

export default Footer;
