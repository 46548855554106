import { Modal } from '@redreamerlab/uikit';
import styled from 'styled-components';
import { Row, fontStyle } from '@styles/common';

export const StyledTitle = styled.div`
  ${fontStyle('24px', '600')};
  color: white;
  font-family: 'Poppins', sans-serif;
`;

export const StyledMessage = styled.div`
  ${fontStyle('16px', '700')};
  margin-top: 8px;
  word-break: break-word;
  font-family: 'Poppins', sans-serif;
  color: white;
`;

export const StyledModal = styled(Modal)`
  & > div:last-child {
    border: solid 5px #00007c;
    background: #000000;
    padding: 48px;
  }
`;

export const StyledCustomRow = styled(Row)`
  &&& {
    button {
      width: 50%;
      margin-top: 12px;
      justify-content: center;
    }
  }
`;
