/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import Logo from '@/assets/nagus.svg';
import { ErrorModal } from '@/components/ErrorModal';
import { Loading } from '@/components/Loading';
import { SuccessModal } from '@/components/SuccessModal';
import loadingEffect from '@/loading.json';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { StyledDesktopRender, StyledIconImg, StyledMobileRender } from '@/styles/common';
import { BindingModal } from './BindingModal';
import { Footer } from './Footer';
import { Header } from './Header';
import { SectionHeader } from './SectionHeader';
import {
  StyledClaim,
  StyledClaimWrapper,
  StyledContent,
  StyledDesktop,
  StyledGap,
  StyledText,
  StyledTitle,
  StyledTitleWrapper,
  StyledWrapper,
} from './Styles';
import { contentText, desktopBannerText, mobileBannerText } from './config';

const Home = () => {
  const { account } = useWeb3React();
  const { joyIdAddress } = useJoyIdWallet();

  const [showBindingModal, setShowBindingModal] = useState<boolean>(false);
  const [isBtnClaim, setIsBtnClaim] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [flash, setFlash] = useState<boolean>(false);

  const address = account || joyIdAddress;

  const claimNFT = () => {
    if (!address) {
      setShowBindingModal(true);
    }
    setIsBtnClaim(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/airdrop`, {
          address,
        });
        setIsSuccess(true);
      } catch (error: any) {
        setError(error.response.data.message || '');
      } finally {
        setIsBtnClaim(false);
        setIsLoading(false);
      }
    };

    if (address && isBtnClaim) {
      fetchData();
    }
  }, [address, isBtnClaim]);

  useEffect(() => {
    if (address) {
      setShowBindingModal(false);
    }
  }, [address]);

  useEffect(() => {
    setInterval(() => {
      setFlash((prev) => !prev);
    }, 500);
  }, []);

  return (
    <>
      <Loading effect={loadingEffect} isLoading={isLoading} />
      <Header onConnect={() => setShowBindingModal(true)} />
      <StyledWrapper>
        <StyledDesktop>
          <StyledIconImg src={Logo} width="100%" />
          <StyledGap>
            <StyledTitleWrapper>{flash && <StyledTitle>夢之大地 30 週年紀念 NFT</StyledTitle>}</StyledTitleWrapper>
            <StyledDesktopRender>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: desktopBannerText,
                }}
              />
            </StyledDesktopRender>
            <StyledMobileRender>
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: mobileBannerText,
                }}
              />
            </StyledMobileRender>
            <StyledClaimWrapper onClick={claimNFT}>
              <StyledClaim>
                <span>{'> (N)FT'}</span>
                <span>【 我要領取 】</span>
              </StyledClaim>
            </StyledClaimWrapper>
          </StyledGap>
          <SectionHeader />
          <StyledGap>
            <StyledContent>
              <StyledText dangerouslySetInnerHTML={{ __html: contentText }} />
            </StyledContent>
          </StyledGap>
        </StyledDesktop>
      </StyledWrapper>
      <Footer />
      <BindingModal opened={showBindingModal} onClose={() => setShowBindingModal(false)} />
      <SuccessModal
        title="Success"
        opened={isSuccess}
        onClose={() => setIsSuccess(false)}
        message="Your NFT has been claimed!"
      />
      <ErrorModal
        title="Error"
        error={{ code: '', message: error, error, name: '' }}
        opened={!!error}
        onClose={() => setError('')}
      />
    </>
  );
};

export default Home;
