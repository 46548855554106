/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { disconnect, signMessage as joyIdSignMessage } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import Web3 from 'web3';
import { Logger } from '@api/logger';
import { SelectWallet, okxWallet } from '@configs/evmConnecters';
import { chainMapping } from '@configs/evmWalletConfigs';
import { useInactiveListener } from '@/hooks/useInactiveListener';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { signMessage } from '@/rpc';
import { AppError } from './pageProvider';

type Props = {
  children: React.ReactNode;
};

interface ContextType {
  selectWallet: SelectWallet | undefined;
  setSelectWallet: React.Dispatch<React.SetStateAction<SelectWallet | undefined>>;
  web3?: Web3;
  currentChainType: string;
  isMainnet: boolean;
  contracts?: object;
  addressConfigs?: object;
  tokenNames?: any[];
  triggerSignWallet: boolean;
  setTriggerSignWallet: React.Dispatch<React.SetStateAction<boolean>>;
  appError: AppError | undefined;
  setAppError: React.Dispatch<React.SetStateAction<AppError | undefined>>;
  deactivateWallet: () => Promise<void>;
}

const ApplicationContext = createContext<ContextType>({
  selectWallet: undefined,
  setSelectWallet: () => {},
  web3: undefined,
  contracts: {},
  addressConfigs: {},
  tokenNames: [],
  currentChainType: '',
  isMainnet: false,
  triggerSignWallet: false,
  setTriggerSignWallet: () => {},
  appError: undefined,
  setAppError: () => {},
  deactivateWallet: () => Promise.resolve(),
});

const Provider = ({ children }: Props) => {
  const { chainId, account, provider, connector } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();

  const userAddress = (account || '').toLowerCase();
  const history = useHistory();

  const [web3, setWeb3] = useState<Web3>();
  const [triggerSignWallet, setTriggerSignWallet] = useState<boolean>(false);
  const [selectWallet, setSelectWallet] = useState<SelectWallet | undefined>();
  const [appError, setAppError] = useState<AppError | undefined>(undefined);

  const deactivateWallet = async () => {
    if (connector) {
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;
    }

    // joyid
    disconnect();

    // okx
    await okxWallet.resetState();
    okxWallet.provider = undefined;
    (okxWallet as any).eagerConnection = undefined;
  };

  const getChainName = () => {
    if (userAddress) return get(chainMapping, [chainId as number, 'name']) || 'eth';
    if (joyIdAddress) return 'polygon';
    return '';
  };

  const isMainnet = get(chainMapping, [chainId as number, 'network']) === 'mainnet';

  useEffect(() => {
    if (provider) {
      const w = new Web3(provider as any);
      window.web3 = w;
      setWeb3(w);
    }
  }, [provider]);

  useEffect(() => {
    history.replace('/');
  }, [userAddress, history, joyIdAddress]);

  useInactiveListener();

  const contexts = {
    selectWallet,
    setSelectWallet,
    web3,
    currentChainType: getChainName(),
    isMainnet,
    triggerSignWallet,
    setTriggerSignWallet,
    appError,
    setAppError,
    deactivateWallet,
  };

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
