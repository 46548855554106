import React from 'react';
import { Modal } from '@components/Modal';
import { SelectWallet, connecterConfigs, connecterList } from '@configs/evmConnecters';
import useApplicationContext from '@providers/applicationContext';
import { AppError } from '@/providers/pageProvider';
import { ConnectButton } from '../ConnectButton';
import { StyledContainer, StyledTitle, StyledWrapper } from './Styles';

type Props = {
  opened: boolean;
  onClose: () => void;
};

const BindingModal = ({ opened, onClose }: Props) => {
  const { deactivateWallet, setAppError } = useApplicationContext();

  const handleMetamskMobile = () => {
    const ua = navigator.userAgent;
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
    const isMobile = isIOS || isAndroid;
    const isMetaMaskApp = /MetaMaskMobile/i.test(ua);
    const link = `${window.location.host}`;

    if (isMobile && !isMetaMaskApp) {
      try {
        window.open(`https://metamask.app.link/dapp/${link}`);
      } catch (error) {
        // cannot open
      }
    }
  };

  const handleOKXMobile = async () => {
    const ua = navigator.userAgent;
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
    const isMobile = isIOS || isAndroid;
    const isOKApp = /OKApp/i.test(ua);
    const link = `${window.location.host}`;

    if (isMobile && !isOKApp) {
      try {
        const encodedUrl = `https://www.okx.com/download?deeplink=${encodeURIComponent(
          `okx://wallet/dapp/url?dappUrl=${encodeURIComponent(link)}`,
        )}`;
        window.open(encodedUrl);
      } catch (error) {
        // cannot open
      }
    }
  };

  return (
    <Modal backgroundColor="transparent" disabledCloseButton opened={opened} onClose={onClose}>
      <StyledWrapper>
        <StyledTitle>連結錢包</StyledTitle>
        <StyledContainer>
          {connecterList.map((key) => {
            const { name, active } = connecterConfigs[key];
            return (
              <ConnectButton
                key={key}
                name={name}
                onClick={async () => {
                  if (name === SelectWallet.META_MASK) {
                    handleMetamskMobile();
                  }
                  if (name === SelectWallet.OKX) {
                    handleOKXMobile();
                  }

                  try {
                    await deactivateWallet();
                    await active();
                  } catch (error) {
                    setAppError(error as AppError);
                  }
                }}
              />
            );
          })}
        </StyledContainer>
      </StyledWrapper>
    </Modal>
  );
};

export default BindingModal;
