import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { fontStyle, size } from '@/styles/common';

export const StyledWrapper = styled.div`
  background-color: #00007c;
  padding: 8px 24px;
  ${size('100%', '52px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledText = styled.div`
  color: #fffb00;
  ${fontStyle('24px', '700')};
`;

export const StyledMain = styled.div`
  color: #ffffff;
  ${fontStyle('24px', '700')};
  display: none;

  @media ${SM_AND_UP_QUERY} {
    display: block;
  }
`;

export const StyledButton = styled.button`
  padding: 4px 12px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  ${fontStyle('16px', '500')};
  color: #ffffff;
  line-height: 24px;
  background: transparent;
  cursor: pointer;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 141px;
`;
