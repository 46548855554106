import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledContent = styled.div`
  margin-top: -3px;
  padding: 11px 24px;
  background-color: #000000;
`;

export const StyledDesktop = styled.div`
  @media ${SM_AND_UP_QUERY} {
    max-width: 1174px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
`;

export const StyledText = styled.div`
  color: #b8b8b8;
  margin-bottom: 4px;
  ${fontStyle('16px', '600')}

  & > a {
    color: #b8b8b8;
    text-decoration-color: #b8b8b8;
  }
`;

export const StyledRow = styled(Row)`
  @media ${SM_AND_UP_QUERY} {
    & > div:first-child {
      margin-right: 80px;
    }
  }
`;
