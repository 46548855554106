import React from 'react';
import { StyledLeft, StyledLeftText, StyledRight, StyledRightText, StyledWrapper } from './Styles';

const SectionHeader = () => (
  <StyledWrapper>
    <StyledLeft>
      <StyledLeftText>作者</StyledLeftText>
      <StyledLeftText>標題</StyledLeftText>
      <StyledLeftText>時間</StyledLeftText>
    </StyledLeft>
    <StyledRight>
      <StyledRightText>lman</StyledRightText>
      <StyledRightText>夢之大地竟然在明年 2024 即將滿 30 週年</StyledRightText>
      <StyledRightText>Mon Feb 20 10:59 2023</StyledRightText>
    </StyledRight>
  </StyledWrapper>
);

export default SectionHeader;
