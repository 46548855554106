import React from 'react';
import { useWeb3React } from '@web3-react/core';
import useApplicationContext from '@/providers/applicationContext';
import { StyledButton, StyledButtonWrapper, StyledMain, StyledText, StyledWrapper } from './Styles';

type Props = {
  onConnect: () => void;
};

const Header = ({ onConnect }: Props) => {
  const { deactivateWallet } = useApplicationContext();

  const { account: address } = useWeb3React();

  return (
    <StyledWrapper>
      <StyledMain>【主功能表】</StyledMain>
      <StyledText>夢之大地</StyledText>
      <StyledButtonWrapper>
        {address ? (
          <StyledButton onClick={deactivateWallet}>Disconnect</StyledButton>
        ) : (
          <StyledButton onClick={onConnect}>Connect Wallet</StyledButton>
        )}
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

export default Header;
