import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const StyledLeft = styled.div`
  background: #c0c0c0;
  padding: 4px 16px;

  & > div:first-child {
    margin-bottom: 12px;
  }

  & > div:nth-child(2) {
    margin-bottom: 38px;
  }

  @media ${SM_AND_UP_QUERY} {
    & > div:nth-child(2) {
      margin-bottom: 12px;
    }
  }
`;

export const StyledRight = styled.div`
  background: #00007c;
  padding: 4px 10px;

  & > div:first-child {
    margin-bottom: 8px;
  }

  & > div:nth-child(2) {
    line-height: 28px;
    margin-bottom: 8px;
  }

  @media ${SM_AND_UP_QUERY} {
    flex: 1;

    & > div:first-child {
      margin-bottom: 12px;
    }

    & > div:nth-child(2) {
      margin-bottom: 12px;
    }
  }
`;

export const StyledLeftText = styled.div`
  ${fontStyle('20px', '500')};
  color: #00007c;
  width: 40px;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('24px', '400')};
    width: auto;
  }
`;

export const StyledRightText = styled.div`
  ${fontStyle('20px', '500')};
  color: #b8b8b8;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('24px', '400')};
  }
`;
