const desktopBannerText = `
  <p>雖然 BBS 的精華年代早已過去，但是在夢大所存在的這三十年中，如同當時架設的初心，</p>
  <p>希望你在這可以留下你年輕時的回憶，多年後你依然可以在這找到你的老友們。</p>
  <p>因此在這即將滿三十年時，我們想透過發行紀念 NFT 來試著將一起經歷過夢大的大家們聚在一起。</p>
  <br/>
  <p>這一切，都不需要你們付出任何費用，就如同當時我們在經營夢大時一樣。</p>
`;

const mobileBannerText = `
  <p>雖然 BBS 的精華年代早已過去，但是在夢大所存在的這三十年中，如同當時架設的初心，希望你在這可以留下你年輕時的回憶，多年後你依然可以在這找到你的老友們。因此在這即將滿三十年時，我們想透過發行紀念 NFT 來試著將一起經歷過夢大的大家們聚在一起。</p>
  <br/>
  <p>這一切，都不需要你們付出任何費用，就如同當時我們在經營夢大時一樣。</p>
`;

const contentText = `<p>我是 #夢之大地 的老爸，這樣說應該勉強還算有道理.</p>
<p>最近跟一些社團的老骨頭們聊天聊著過往的一些回憶，例如夢大居然到現在還屹立不搖的存在著，這算是另外一種歷史的見證.</p>
<p>然後疑似，夢大可能是既存最老的 BBS 站台, 別棚的 Ptt 晚一屆，真正的老骨頭中山大學美麗島早就關了。</p>
<p>而夢大雖然現在的大學生包含自己成大人早就幾乎不太使用. 但是最輝煌的時候夢大曾經是全台前三大站.</p>
<p>聊到後來，猛地發現， 夢之大地竟然在明年 2024 即將滿 30 週年，我還特地怕記憶不清跑去翻找了歷史資料，還能找到當時在站上地一些記錄。</p>
<p>三十年ㄟ！！！！ 雖然已經不多人用，但是這三十年卻曾經地串起了許多人的青春回憶，有多少人在上面交友，把妹，談戀愛，失戀，後來因著夢大的關係找到了你的另一半，這些都是當年傻傻大學生的我不可能預想的到的事情。</p>
<p>三十年，夢大是我身為一個科技宅在當年單純只想搞 Internet 時的一個作品，卻深深的影響了我自己以及在這上面留下回憶的人們。</p>
<p>所以，在即將滿三十年之際，</p>
<p>#我想做一件大事情</p>
<p>我想要邀請願意參加的站友們，一起共創，我想要借這機會發行夢之大地的紀念 NFT 給與夢之大地有過連結的站友們。</p>
<p>我想由我這個夢大創辦人來發起應該還是有著代表性的，完全站友不需要花費(連 Gas 都不需要, 給看懂的人) 僅是希望借這特別的時間點，為夢大留下一個恆久的紀念.</p>
<p>但是這想法剛開始而已，而且我需要曾經是站友們的幫忙及共創.</p>
<p>我希望透過共創的方式，大家一起來構思這夢大的紀念 NFT 可以長怎麼樣, 用怎麼樣的方式來傳遞給大家.</p>
<p>陸續在繼續在這邊來公布怎麼做. 首先就是要誠徵願意玩的站友們了！有興趣的只要你有美術設計的能力，或者有任何的想法都可以來一起共創！</p>
<p>希望在明年 2024 夢大三十年之際，能夠將這屬於我們大家的回憶一起創造出來.</p>
<p>#由社群而生 #回饋給社群</p>`;

export { desktopBannerText, mobileBannerText, contentText };
