import Button from '@mui/material/Button';
import styled from 'styled-components';
import { fontStyle } from '@/styles/common';

export const CustomMuiButton = styled(Button)`
  &&& {
    width: 100%;
    padding: 8px 16px;
    background: transparent;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    text-transform: none;
    margin-bottom: 18px;
    justify-content: flex-start;
    font-family: 'Noto Sans TC', sans-serif;
    transition: none;
    ${fontStyle('20px', '400')}

    &:hover {
      background: #ffffff;
      color: #000000;
    }
  }
`;
