import React from 'react';
import { initConfig } from '@joyid/evm';
import { WalletProvider } from '@redreamerlab/uikit';
import 'normalize.css';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import './api/axios.config';
import { metaMask, metaMaskHooks, walletConnectV2, walletConnectV2Hooks } from './configs/evmConnecters';
import { joyIDConfig } from './configs/joyIDConfig';
import reportWebVitals from './reportWebVitals';

initConfig(joyIDConfig);

const connectors: any = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <WalletProvider connectors={connectors}>
    <App />
  </WalletProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
