import { JoyIDProvider } from '@joyid/ethers';
import { chains } from './evmWalletConfigs';

const mumbaiConfig = chains.find((c) => c.network === 'mainnet' && c.name === 'polygon');
const joyidAppURL = 'https://app.joy.id';

const rpcURL = mumbaiConfig!.rpc;

const network = {
  chainId: mumbaiConfig!.id,
  name: mumbaiConfig!.name,
};

const joyIDConfig = {
  name: 'RE:DREAMER',
  logo: 'https://hongkongdirlpass.domin.foundation/favicon.svg',
  network,
  rpcURL,
  joyidAppURL,
};

const joyIDProvider = new JoyIDProvider(rpcURL, network, joyIDConfig);

export { rpcURL, network, joyIDConfig, joyIDProvider };
