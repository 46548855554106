import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDesktop = styled.div`
  @media ${SM_AND_UP_QUERY} {
    max-width: 1177px;
  }
`;

export const StyledGap = styled.div`
  padding: 0 24px;
`;

export const StyledTitleWrapper = styled.div`
  height: 74px;
  margin-bottom: 24px;

  @media ${SM_AND_UP_QUERY} {
    height: 56px;
  }
`;

export const StyledTitle = styled.div`
  ${fontStyle('32px', '600')};
  color: #45f3f1;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('48px', '600')};
  }
`;

export const StyledText = styled.div`
  ${fontStyle('20px', '400')};
  color: #b8b8b8;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;

  p {
    margin: 0;
  }

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('24px', '400')};
    line-height: 36px;
  }
`;

export const StyledClaimWrapper = styled.div`
  padding: 0 22px;
  margin: 45px auto;
  cursor: pointer;

  @media ${SM_AND_UP_QUERY} {
    width: 337px;
    margin-top: 102px;
    margin-bottom: 150px;
  }
`;

export const StyledClaim = styled.button`
  color: #fdff04;
  background-color: #ab0014;
  padding: 2px 6px;
  border: none;
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${fontStyle('20px', '600')};

  &:hover {
    color: #000000;
    background-color: #ffffff;
  }
`;

export const StyledContent = styled.div`
  margin-bottom: 62px;

  p {
    margin: 0;
    text-align: left;
  }
`;
