import Ethereum from '../assets/Ethereum.svg';
import Polygon from '../assets/Polygon.svg';
import WebsiteBg from '../assets/background.svg';
import backgroundMobile from '../assets/background_mobile.svg';
import linkedin from '../assets/c_linkedin.svg';
import subtack from '../assets/c_subtack.svg';
import telegram from '../assets/c_telegram.svg';
import x from '../assets/c_x.svg';
import checkMark from '../assets/check_mark.svg';
import claimBackground from '../assets/claim_background.png';
import claimBackgroundMobile from '../assets/claim_background_mobile.svg';
import close from '../assets/close.svg';
import downArrow from '../assets/down_arrow.svg';
import gridiconsNotice from '../assets/gridicons_notice.svg';
import imageError from '../assets/img_error.svg';
import ghostParty from '../assets/img_ghost_party.svg';
import walletJoyId from '../assets/img_joyid.svg';
import refresh from '../assets/refresh.svg';
import diamond from '../assets/ri_shining-fill.svg';
import unbinding from '../assets/unbinding.svg';
import walletMetamask from '../assets/wallet-metamask.svg';
import walletConnect from '../assets/wallet_connect.svg';
import walletTT from '../assets/wallet_tt.svg';
import whiteTriangle from '../assets/white_triangle.svg';
import zondiconsNetwork from '../assets/zondicons_network.svg';
import zondiconsNetworkPurple from '../assets/zondicons_network_purple.svg';

export const EthereumIcon = Ethereum;
export const PolygonIcon = Polygon;
export const unbindingIcon = unbinding;
export const zondiconsNetworkIcon = zondiconsNetwork;
export const zondiconsNetworkPurpleIcon = zondiconsNetworkPurple;
export const checkMarkIcon = checkMark;
export const closeIcon = close;
export const refreshIcon = refresh;
export const downArrowIcon = downArrow;
export const gridiconsNoticeIcon = gridiconsNotice;
export const whiteTriangleIcon = whiteTriangle;
export const walletMetamaskImg = walletMetamask;
export const walletConnectImg = walletConnect;
export const walletTTImg = walletTT;
export const walletJoyID = walletJoyId;
export const ghostPartyIcon = ghostParty;
export const diamondIcon = diamond;
export const linkedinIcon = linkedin;
export const telegramIcon = telegram;
export const subtackIcon = subtack;
export const xIcon = x;

export const imageErrorIcon = imageError;
export const WebsiteBgImage = WebsiteBg;
export const backgroundMobileImage = backgroundMobile;
export const claimBackgroundMobileImage = claimBackgroundMobile;
export const claimBackgroundImage = claimBackground;
